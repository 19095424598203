import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import "./CreditCards.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Table from 'react-bootstrap/Table'
import { calculateTotals } from "../../../libs/calculateTotals";

export default function CreditCards() {
  const [typeOfItem] = useState("income");
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    function loadBudgetItems(budgetItems) {
      return API.get("budget", `/list-items/${typeOfItem}`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated, typeOfItem]);



  function renderBudgetItemsList(budegetItems) {

//    const divStyle = {
//      float: 'right', // note the capital 'W' here
//      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
//    };

  let weekTotal = 0;
  let fortnightTotal = 0;
  let monthTotal = 0;
  let quarterTotal = 0;
  let yearTotal = 0;
  
  //let quarterAmount = 0;

  for (let budgetItem of budgetItems) {
    console.log(budgetItem.amountOfItem);
    console.log(budgetItem.frequencyOfItem);

    weekTotal = parseInt(weekTotal) + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK"));
    fortnightTotal = parseInt(fortnightTotal) + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT"));
    monthTotal = parseInt(monthTotal) + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH"));
    quarterTotal = parseInt(quarterTotal) + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER"));
    yearTotal = parseInt(yearTotal) + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"));

    console.log(fortnightTotal);
  }

    return (
      <>
        <h3>Total - Averaged over 12 months</h3>

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th scope="col">Week</th>
              <th scope="col">Fortnight</th>
              <th scope="col">Month</th>
              <th scope="col">Quarter</th>
              <th scope="col">Year</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td>${weekTotal}</td>
                <td>${fortnightTotal}</td>
                <td>${monthTotal}</td>
                <td>${quarterTotal}</td>
                <td>${yearTotal}</td>
              </tr>
        </tbody>
        </Table>

        <hr></hr>

        <LinkContainer to="/income/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Add a new income</span>
          </ListGroup.Item>
        </LinkContainer>
        {budgetItems.map(({ itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem }) => (

          <LinkContainer key={itemId} to={`/items/${itemId}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {subtypeOfItem.trim().split("\n")[0]}&nbsp;-&nbsp;
              </span>
              <span className="text-muted">
                ${amountOfItem} / {frequencyOfItem}
              </span>
              <br></br>
              <span className="text-muted">
                {descriptionOfItem}
              </span>
            </ListGroup.Item>
          </LinkContainer>
                  
        ))}

        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Credit Cards</h2>
        <ListGroup>{!isLoading && renderBudgetItemsList(budgetItems)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="CreditCards">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}