import React from "react";

import { Pie } from "react-chartjs-2";

class PercentPieChart extends React.Component {
constructor(props) {
 super(props);

 this.state = {
   chartData: {
    labels: props.percents.labels,
     datasets: [
       {
         label: "Percentages",
         backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 206, 86, 0.2)',
    
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
          ],
         data: props.percents.data,
         borderWidth: 1
       }
     ]
   }
 };
}

//componentDidUpdate(prevProps) {
//    if (prevProps.percents !== this.props.percents) {
//       let chartData = this.state.percents;
//  
//       chartData.datasets[0].data = this.props.percents
//       this.setState({chartData});
//    }
//  }

render() {
 return (
 <Pie data={this.state.chartData} height={"400%"} width={"100%"} options={{ maintainAspectRatio: false }}/>
 );
}
}

export default PercentPieChart;