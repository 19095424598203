import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
//import Table from 'react-bootstrap/Table'
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import "./Overview.css";
import { API } from "aws-amplify";
import {isMobile} from 'react-device-detect';
import PercentSubTypePieChart from "./Charts/PercentSubTypePieChart";
import BootstrapTable from "react-bootstrap-table-next";
import * as TableConstants from "./Tables/constants.js";
import * as TableColumns from "./Tables/columns.js";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

export default function PortfolioOverview() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const assets = await loadAssets();
        setAssets(assets);
      } catch (e) {
        onError(e);
      }

      try {
        const liabilities = await loadLiabilities();
        setLiabilities(liabilities);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }

    function loadAssets(assets) {
      return API.get("portfolio", `/assets/list`);
    }

    function loadLiabilities(liabilities) {
      return API.get("portfolio", `/liabilities/list`);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function renderPortofolioList(assets) {

    if (isMobile) { require('./custom.css'); }

    var objIndex;
    let assetSubtypes = [];
    var assetTotals = 0;
    let liabilitySubtypes = [];
    var liabilityTotals = 0;
    var assetDates = [];
    var liabilityDates = [];

    var cashTypes = [];
    var cryptoTypes = [];
    var sharesTypes = [];

    var totalCash = 0;
    var totalCrypto = 0;
    var totalStock = 0;

    var percentOfCashAsset = {
      labels: [],
      data: [],
    }

    var percentOfCryptoAsset = {
      labels: [],
      data: [],
    }

    var percentOfStockAsset = {
      labels: [],
      data: [],
    }

    for (let assetItem of assets) {
      if (assetItem.active === "false"){
        break;
      }

      if (assetItem.typeOfAsset === "Cash"){
        if (cashTypes.some(subTypeItem => subTypeItem.subtype === assetItem.assetName)){
          objIndex = cashTypes.findIndex(subTypeItem => subTypeItem.subtype === assetItem.assetName);
          cashTypes[objIndex].totalAmount = parseInt(cashTypes[objIndex].assetTotalPrice) + parseInt(assetItem.assetTotalPrice);
        } else {
          let subTypeItem = {
            "subtype": assetItem.assetName,
            "assetNameCode": assetItem.assetName + " (" + assetItem.assetCode + ")",
            "totalAmount": parseInt(assetItem.assetTotalPrice)
          }
          cashTypes.push(subTypeItem);
        }

        totalCash = parseInt(totalCash + assetItem.assetTotalPrice) 
      }

      if (assetItem.typeOfAsset === "Crypto"){
        if (cryptoTypes.some(subTypeItem => subTypeItem.subtype === assetItem.assetName)){
          objIndex = cryptoTypes.findIndex(subTypeItem => subTypeItem.subtype === assetItem.assetName);
          cryptoTypes[objIndex].totalAmount = parseInt(cryptoTypes[objIndex].assetTotalPrice) + parseInt(assetItem.assetTotalPrice);
        } else {
          let subTypeItem = {
            "subtype": assetItem.assetName,
            "assetNameCode": assetItem.assetName + " (" + assetItem.assetCode + ")",
            "totalAmount": parseInt(assetItem.assetTotalPrice)
          }
          cryptoTypes.push(subTypeItem);
        }

        totalCrypto = parseInt(totalCrypto + assetItem.assetTotalPrice) 
      }

      if (assetItem.typeOfAsset === "Stock"){
        if (sharesTypes.some(subTypeItem => subTypeItem.subtype === assetItem.assetName)){
          objIndex = sharesTypes.findIndex(subTypeItem => subTypeItem.subtype === assetItem.assetName);
          sharesTypes[objIndex].totalAmount = parseInt(sharesTypes[objIndex].assetTotalPrice) + parseInt(assetItem.assetTotalPrice);
        } else {
          let subTypeItem = {
            "subtype": assetItem.assetName,
            "assetNameCode": assetItem.assetName + " (" + assetItem.assetCode + ")",
            "totalAmount": parseInt(assetItem.assetTotalPrice)
          }
          sharesTypes.push(subTypeItem);
        }

        totalStock = parseInt(totalStock + assetItem.assetTotalPrice) 
      }


      assetDates.push(new Date(assetItem.assetDate));

      assetTotals = parseInt(assetTotals) + parseInt(assetItem.assetTotalPrice);

      if (assetSubtypes.some(subTypeItem => subTypeItem.subtype === assetItem.typeOfAsset)){
        objIndex = assetSubtypes.findIndex(subTypeItem => subTypeItem.subtype === assetItem.typeOfAsset);
        assetSubtypes[objIndex].totalAmount = assetSubtypes[objIndex].totalAmount + assetItem.assetTotalPrice
      } else {
        let subTypeItem = {
          "subtype": assetItem.typeOfAsset,
          "totalAmount": assetItem.assetTotalPrice
        }
        assetSubtypes.push(subTypeItem);
      }

    }

    cashTypes.forEach(cashType => {
      cashType["totalAmountString"] = cashType.totalAmount.toLocaleString();
      cashType["percentage"] = parseFloat(parseInt(cashType.totalAmount) / parseInt(totalCash) * 100).toFixed(1);
      percentOfCashAsset.labels.push(cashType.subtype);
      percentOfCashAsset.data.push(cashType.totalAmount);
    });

    cryptoTypes.forEach(cryptoTypes => {
      cryptoTypes["totalAmountString"] = cryptoTypes.totalAmount.toLocaleString();
      cryptoTypes["percentage"] = parseFloat(parseInt(cryptoTypes.totalAmount) / parseInt(totalCrypto) * 100).toFixed(1);
      percentOfCryptoAsset.labels.push(cryptoTypes.subtype);
      percentOfCryptoAsset.data.push(cryptoTypes.totalAmount);
    });

    sharesTypes.forEach(sharesType => {
      sharesType["totalAmountString"] = sharesType.totalAmount.toLocaleString();
      sharesType["percentage"] = parseFloat(parseInt(sharesType.totalAmount) / parseInt(totalStock) * 100).toFixed(1);
      percentOfStockAsset.labels.push(sharesType.subtype);
      percentOfStockAsset.data.push(sharesType.totalAmount);
    });

    assetSubtypes.forEach(assetSubType => {
      assetSubType["totalAmountString"] = assetSubType.totalAmount.toLocaleString();
      assetSubType["percentage"] = parseFloat(assetSubType.totalAmount / assetTotals * 100).toFixed(1);
      assetSubType["percentageText"] = assetSubType.percentage + "%";
    });

    // Percentage of assets
    var percentOfAssetSubtype = {
      labels: [],
      data: []
    }
    
    assetSubtypes.forEach(assetSubtype => {
      percentOfAssetSubtype.labels.push(assetSubtype.subtype);
      percentOfAssetSubtype.data.push(assetSubtype.percentage);
    });

    // Start liabliities
    for (let liabiltiesItem of liabilities) {
      if (liabiltiesItem.active === "false"){
        break;
      }

      liabilityDates.push(new Date(liabiltiesItem.liabilityCurrentDate));

      liabilityTotals = parseInt(liabilityTotals) + parseInt(liabiltiesItem.liabilityCurrentAmount);

      if (liabilitySubtypes.some(subTypeItem => subTypeItem.subtype === liabiltiesItem.typeOfLiability)){
        objIndex = liabilitySubtypes.findIndex(subTypeItem => subTypeItem.subtype === liabiltiesItem.typeOfLiability);
        liabilitySubtypes[objIndex].totalAmount = liabilitySubtypes[objIndex].totalAmount + liabiltiesItem.liabilityCurrentAmount
      } else {
        let subTypeItem = {
          "subtype": liabiltiesItem.typeOfLiability,
          "totalAmount": liabiltiesItem.liabilityCurrentAmount
        }
        liabilitySubtypes.push(subTypeItem);
      }

    }

    console.log("Total liability: " + liabilityTotals);

    liabilitySubtypes.forEach(liabilitySubType => {
      liabilitySubType["totalAmountString"] = parseFloat(liabilitySubType.totalAmount).toLocaleString();
      liabilitySubType["percentage"] = parseFloat(liabilitySubType.totalAmount / liabilityTotals * 100).toFixed(1);
      liabilitySubType["percentageText"] = liabilitySubType.percentage + "%";
    });

    // Percentage of assets
    var percentOfLiabilitySubtype = {
      labels: [],
      data: []
    }
    
    liabilitySubtypes.forEach(liabilitySubtype => {
      percentOfLiabilitySubtype.labels.push(liabilitySubtype.subtype);
      //percentOfLiabilitySubtype.data.push(liabilitySubtype.totalAmount);
      percentOfLiabilitySubtype.data.push(liabilitySubtype.percentage);
    });

    var percentOfAssetLiabilityTotal = {
      labels: ["Liabilities", "Assets"],
      data: [liabilityTotals, assetTotals]
    }

    var latestAssetDate  = new Date(Math.max.apply(null, assetDates));
    var latestLiabilityDate = new Date(Math.max.apply(null, liabilityDates));
    //var minimumDate=new Date(Math.min.apply(null, dates));



    return (
      <>      
        <div class="row" >
         <div class="col">
           <div class="text-center date-left">
             Last Asset Update: 
             <br></br>
             {new Date(latestAssetDate).toISOString().slice(0,10)}
           </div>
           <hr></hr>
         </div>
         <div class="col">
           <div class="text-center">
             Last Liability Update: 
             <br></br>
             {new Date(latestLiabilityDate).toISOString().slice(0,10)}
           </div>
           <hr></hr>
         </div>
        </div>

        <h3 class="text-center">Overview</h3>

        <div class="row" >
         <div class="col">
           <div class="text-right">
             <div>
               Assets:&nbsp;
             </div>
             <div>
               Liabilities:&nbsp;
             </div>
             <div>
              <aa>&nbsp;&nbsp;&nbsp;Position:&nbsp;</aa>
             </div>
             <br></br>
           </div>
         </div>
         <div class="col">
           <div class="text-left">
             <div>
             { assetTotals.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) }
             </div>
             <div>
               { liabilityTotals.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) }
             </div>
             <div>
             <aa>{ (assetTotals - liabilityTotals).toLocaleString( 'en-AU', { style: 'currency', currency: 'AUD' }) }</aa>
             </div>
           </div>
         </div>
        </div>

        <div>
          <PercentSubTypePieChart 
            percents={percentOfAssetLiabilityTotal}
          />
        </div>
        <div>
          <hr></hr>
        </div>
        <h3 class="text-center"><header-underline>Breakdown</header-underline></h3>
        <div class="row" >
           <div class="col-sm">
             <div>
               <h3 class="text-center">Assets</h3>
             </div>
             <div>
             <PercentSubTypePieChart 
               percents={percentOfAssetSubtype}
             />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ assetSubtypes }
                 defaultSorted={ TableConstants.sortPercentage }
                 columns={ TableColumns.assetSubTypeColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
           <div class="col-sm">
             <div>
               <h3 class="text-center">Liabilities</h3>
             </div>
             <div>
               <PercentSubTypePieChart 
                 percents={percentOfLiabilitySubtype}
               />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ liabilitySubtypes }
                 defaultSorted={ TableConstants.sortPercentage }
                 columns={ TableColumns.liabilitySubTypeColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
        </div>

        <div>
          <hr></hr>
        </div>

        <Button
        onClick={() => setOpen(!open)}
        aria-controls="collapse-budget-items"
        aria-expanded={open}
        >
        Show/Hide Asset Breakdown
        </Button>

        <Collapse in={open}>
          <div id="collapse-budget-items">
<div><hr></hr></div>
        <h3 class="text-center"><header-underline>Asset Breakdown</header-underline></h3>
        <div class="row" >
           <div class="col-md">
             <div>
               <h3 class="text-center">Cash</h3>
             </div>
             <div>
                <PercentSubTypePieChart 
                  percents={percentOfCashAsset}
                />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ cashTypes }
                 defaultSorted={ TableConstants.sortPercentage }
                 columns={ TableColumns.assetCashBreakdownColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
           <div class="col-md">
             <div>
               <h3 class="text-center">Crypto</h3>
             </div>
             <div>
               <PercentSubTypePieChart 
                 percents={percentOfCryptoAsset}
               />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ cryptoTypes }
                 defaultSorted={ TableConstants.sortPercentage }
                 columns={ TableColumns.assetCryptoBreakdownColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
           <div class="col-md">
             <div>
               <h3 class="text-center">Stock</h3>
             </div>
             <div>
               <PercentSubTypePieChart 
                 percents={percentOfStockAsset}
               />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ sharesTypes }
                 defaultSorted={ TableConstants.sortPercentage }
                 columns={ TableColumns.assetStockBreakdownColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
        </div>

        </div>
        </Collapse>

        <div>
          <hr></hr>
        </div>

        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Portfolio Overview</h2>
        <ListGroup>{!isLoading && renderPortofolioList(assets)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Overview">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}