    import * as TableConstants from "./constants"

    export const tableHeaders = "#8c96d7";

    // Totals Table columns
    export const totalColumns = [{
        dataField: "index",
        text: "index",
        sort: true,
        hidden: true,
      }, {
        dataField: "type",
        text: "2021-2022",
        formatter: TableConstants.afterTaxPayFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "weekly",
        text: "Weekly",
        formatter: TableConstants.afterTaxPayFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "fortnightly",
        text: "Fortnightly",
        formatter: TableConstants.afterTaxPayFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "monthly",
        text: "Monthly",
        formatter: TableConstants.afterTaxPayFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "annually",
        text: "Annually",
        formatter: TableConstants.afterTaxPayFormatter,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }];