export const CHART_COLORS = {
  green: 'rgba(75, 192, 192, 0.4)',
  blue: 'rgba(54, 162, 235, 0.4)',
  red: 'rgba(255, 99, 132, 0.4)',
  orange: 'rgba(255, 159, 64, 0.4)',
  yellow: 'rgba(255, 205, 86, 0.4)',
  purple: 'rgba(153, 102, 255, 0.4)',
  grey: 'rgba(201, 203, 207, 0.4)'
};

export const CHART_COLORS_BORDER = {
  green: 'rgba(75, 192, 192, 1)',
  blue: 'rgba(54, 162, 235, 1)',
  red: 'rgba(255, 99, 132, 1)',
  orange: 'rgba(255, 159, 64, 1)',
  yellow: 'rgba(255, 205, 86, 1)',
  purple: 'rgba(153, 102, 255, 1)',
  grey: 'rgba(201, 203, 207, 1)'
};

    // Function to format money fields in $xxx,yyy format
    export function moneyFormatter(cell, row) {
      if (row.onSale) {
        return (
          <span>
            <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong>
          </span>
        );
      }
    
      return (
        
        <span>${ cell } </span>
      );
    }

    export function percentFormatter(cell, row) {
      if (row.onSale) {
        return (
          <span>
            <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong>
          </span>
        );
      }
    
      return (
        
        <span>{ cell }% </span>
      );
    }

    // Determine colours on table
    export const rowStyleTotals = (row) => {
      const style = {};
      if (row.typeTotal === "Leftover" && row.amountYear < 0) {
        style.backgroundColor = "#d9534f";
      } else if (row.typeTotal === "Leftover" && row.amountYear >= 0) {
        style.backgroundColor = " #5cb85c";
      } else {
        //style.backgroundColor = tableRows;
      }

      return style;
    }

    // Default sort for the show all items table
    export const sortAllItems = [{
      dataField: "typeOfItem",
      order: "asc"
    }];

    // Default sort for the show totals table
    export const sortTotals = [{
      dataField: "index",
      order: "asc"
    }];

    // Default sort by percentage
    export const sortPercentage = [{
      dataField: "percentage",
      order: "asc"
    }];