    import * as TableConstants from "./constants"

    export const tableHeaders = "#8c96d7";

    // Totals Table columns
    export const totalColumns = [{
        dataField: "index",
        text: "index",
        sort: true,
        hidden: true,
      }, {
        dataField: "typeTotal",
        text: "Type",
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "amountWeekPretty",
        text: "Week",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountFortnightPretty",
        text: "Fortnight",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountMonthPretty",
        text: "Month",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountQuarterPretty",
        text: "Quarter",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountYearPretty",
        text: "Year",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }];
  
      // Columns for table showing individual items
      export const itemColumns = [{
        dataField: "typeOfItem",
        text: "Type",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "subtypeOfItem",
        text: "Subtype",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "descriptionOfItem",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "amountOfItem",
        text: "Amount",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseFloat(a.replace(/,/g,''), 10)
          const numB = parseFloat(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }, {
        dataField: "frequencyOfItem",
        text: "Frequency",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }];
  

    // Columns to show for liability sub type table
    export const liabilitySubTypeColumns = [{
      dataField: "subtype",
      text: "Type",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
    }, {
      dataField: "totalAmountString",
      text: "Amount",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
      formatter: TableConstants.moneyFormatter,
      sortFunc: (a, b, order) => {
        const numA = parseFloat(a.replace(/,/g,''), 10)
        const numB = parseFloat(b.replace(/,/g,''), 10)
        if (order === "asc") {
            return numB - numA;
        }
        return numA - numB;
      }
    }, {
      dataField: "percentage",
      text: "Percentage",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
      formatter: TableConstants.percentFormatter,
      sortFunc: (a, b, order) => {
        const numA = parseFloat(a.replace(/,/g,''), 10)
        const numB = parseFloat(b.replace(/,/g,''), 10)
        if (order === "asc") {
            return numB - numA;
        }
        return numA - numB;
      }
    }];

      // Columns to show for asset-crypto breakdown table
      export const assetSubTypeColumns = [{
        dataField: "subtype",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "totalAmountString",
        text: "Amount",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseInt(a.replace(/,/g,''), 10)
          const numB = parseInt(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }, {
        dataField: "percentage",
        text: "Percentage",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.percentFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseFloat(a.replace(/,/g,''), 10)
          const numB = parseFloat(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }];

      // Columns to show for asset-cash breakdown table
      export const assetCashBreakdownColumns = [{
        dataField: "assetNameCode",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "totalAmountString",
        text: "Amount",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseInt(a.replace(/,/g,''), 10)
          const numB = parseInt(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }, {
        dataField: "percentage",
        text: "%",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.percentFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseFloat(a.replace(/,/g,''), 10)
          const numB = parseFloat(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }];

      // Columns to show for asset-crypto breakdown table
      export const assetCryptoBreakdownColumns = [{
        dataField: "assetNameCode",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "totalAmountString",
        text: "Amount",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseInt(a.replace(/,/g,''), 10)
          const numB = parseInt(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }, {
        dataField: "percentage",
        text: "%",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.percentFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseFloat(a.replace(/,/g,''), 10)
          const numB = parseFloat(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }];

      // Columns to show for asset-crypto breakdown table
      export const assetStockBreakdownColumns = [{
        dataField: "assetNameCode",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "totalAmountString",
        text: "Amount",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseInt(a.replace(/,/g,''), 10)
          const numB = parseInt(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }, {
        dataField: "percentage",
        text: "%",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.percentFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseFloat(a.replace(/,/g,''), 10)
          const numB = parseFloat(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }];