export function calculateTotals(amount, frequencyFrom, frequencyTo) { 
//  console.log("Calculate:");
//  console.log("Amount: " + amount);
//  console.log("Frequency From: " + frequencyFrom);
//  console.log("Frequency To: " + frequencyTo);

  if (frequencyTo === "YEAR"){
    if (frequencyFrom === "YEAR") {
        return amount ;
    } else if (frequencyFrom === "QUARTER"){
        return amount * 4;
    } else if (frequencyFrom === "MONTH"){
        return amount * 12;
    } else if (frequencyFrom === "FORTNIGHT"){
        return amount * 26;
    } else if (frequencyFrom === "WEEK"){
        return amount * 52;
    }
  } else if (frequencyTo === "QUARTER") {
    if (frequencyFrom === "QUARTER"){
        return amount;
    } else {
        return calculateTotals(amount, frequencyFrom, "YEAR") / 4;
    }
  } else if (frequencyTo === "MONTH") {
      if (frequencyFrom === "MONTH"){
          return amount;
      } else {
          return calculateTotals(amount, frequencyFrom, "YEAR") / 12;
      }
  } else if (frequencyTo === "FORTNIGHT"){
      if (frequencyFrom === "FORTNIGHT"){
          return amount;
      } else {
          return calculateTotals(amount, frequencyFrom, "YEAR")  / 26;
      }
  } else if (frequencyTo === "WEEK"){
      if (frequencyFrom === "WEEK"){
          return amount;
      } else {
          return calculateTotals(amount, frequencyFrom, "YEAR")  / 52;
      }
  }
  return 0;
  }