import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import "./Liabilities.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Row, Col } from "react-bootstrap";

export default function Liabilities() {
  const [typeOfItem] = useState("asset");
  const [assets, setAssets] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  var tableHeaders = "#8c96d7";

  useEffect(() => {

    function loadAssets(assets) {
      return API.get("portfolio", `/liabilities/list`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const assets = await loadAssets();
        setAssets(assets);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated, typeOfItem]);



  function renderAssetsList(assets) {

    // Do Stuff

    //assets.forEach(asset => {
    //  asset["assetTotalPrice"] = asset.assetTotalPrice.toLocaleString(undefined, {maximumFractionDigits:5});
    //});

    //assets.forEach(asset => {
    //  asset["assetSellPricePretty"] = parseFloat(asset.assetSellPrice).toLocaleString(undefined, {maximumFractionDigits:5});
    //});


    function priceFormatter(cell, row) {
      if (row.onSale) {
        return (
          <span>
            <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong>
          </span>
        );
      }
    
      return (
        
        <span>${ cell } </span>
      );
    }
    
    const sortType = [{
      dataField: "typeOfAsset",
      order: "asc"
    }];

    const cryptoColumns = [{
      dataField: "liabilityId",
      text: "liabilityId",
      headerStyle: {
        backgroundColor: tableHeaders
      },
	  hidden: true ,
    }, {
      dataField: "liabilityCurrentDate",
      text: "Date",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      }
    }, {
      dataField: "typeOfLiability",
      text: "Type",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
    }, {
      dataField: "liabilityName",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
    }, {
      dataField: "liabilityNote",
      text: "Note",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      }, 
    }, {
      dataField: "liabilityStartDate",
      text: "Start Date",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
    }, {
      dataField: "liabilityStartAmount",
      text: "Start Amount",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
      formatter: priceFormatter,
      sortFunc: (a, b, order) => {
        const numA = parseFloat(a.replace(/,/g,''), 10)
        const numB = parseFloat(b.replace(/,/g,''), 10)
        if (order === "asc") {
            return numB - numA;
        }
        return numA - numB;
      }
    }, {
      dataField: "liabilityCurrentDate",
      text: "Current Date",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      }
    }, {
      dataField: "liabilityCurrentAmount",
      text: "Current Amount",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      },
      formatter: priceFormatter,
    }, {
      dataField: "liabilityActive",
      text: "Active",
      sort: true,
      headerStyle: {
        backgroundColor: tableHeaders
      }
    }];

    return (
      <>
        <hr></hr>

        <LinkContainer to="/portfolio/liabilities/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Add a new liability</span>
          </ListGroup.Item>
        </LinkContainer>
        <div class="text-center">
        <Container>
          <Row>
        {assets.map(({ liabilityId, liabilityCurrentDate, typeOfLiability, liabilityName, liabilityNote, liabilityCurrentAmount }) => (
          <Col md="4" xs="6">
          <LinkContainer key={liabilityId} to={`/portfolio/liabilities/${liabilityId}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {typeOfLiability.trim().split("\n")[0]}
              </span>
              <br></br>
              <span className="text-muted">
                {liabilityCurrentDate}
              </span>
              <br></br>
              <span className="text-muted">
                {liabilityName}
              </span>
              <br></br>
              <span className="text-muted">
                {liabilityNote}
              </span>
              <br></br>
              <span className="font-weight-bold">
              ${liabilityCurrentAmount}
              </span>
            </ListGroup.Item>
          </LinkContainer>                 
          </Col>
        ))}
        </Row>
        </Container>
        </div>
<div><hr></hr></div>
<BootstrapTable bootstrap4 keyField="assetId" defaultSorted= { sortType } data={ assets } columns={ cryptoColumns } wrapperClasses="table-responsive" striped hover condensed />

        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderAssets() {
    return (
      <div className="assets">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Liabilities</h2>
        <ListGroup>{!isLoading && renderAssetsList(assets)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Liabilities">
      {isAuthenticated ? renderAssets() : renderLander()}
    </div>
  );
}