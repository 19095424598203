import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import "./Income.css";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { calculateTotals } from "../../../libs/calculateTotals";
import BootstrapTable from 'react-bootstrap-table-next';
import ItemListGroupMap from "../Common/ItemListGroupMap";

export default function Income() {
  const [typeOfItem] = useState("income");
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  var tableHeaders = "#8c96d7";

  useEffect(() => {

    function loadBudgetItems(budgetItems) {
      return API.get("budget", `/list-items/${typeOfItem}`);
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated, typeOfItem]);



  function renderBudgetItemsList(budegetItems) {

//    const divStyle = {
//      float: 'right', // note the capital 'W' here
//      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
//    };

  let incomeTotals = [];
  var index;
  var incomeTotalsIndex;
  
  //let quarterAmount = 0;

  for (let budgetItem of budgetItems) {
    if (incomeTotals.some(budgetLineTotal => budgetLineTotal.typeTotal === budgetItem.typeOfItem)){
      incomeTotalsIndex = incomeTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === budgetItem.typeOfItem);
      incomeTotals[incomeTotalsIndex].amountWeek = incomeTotals[incomeTotalsIndex].amountWeek + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK"))
      incomeTotals[incomeTotalsIndex].amountFortnight = incomeTotals[incomeTotalsIndex].amountFortnight + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT"))
      incomeTotals[incomeTotalsIndex].amountMonth = incomeTotals[incomeTotalsIndex].amountMonth + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH"))
      incomeTotals[incomeTotalsIndex].amountQuarter = incomeTotals[incomeTotalsIndex].amountQuarter + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER"))
      incomeTotals[incomeTotalsIndex].amountYear = incomeTotals[incomeTotalsIndex].amountYear + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
    } else {
      let budgetLineTotal = {
        "index": index,
        "typeTotal": budgetItem.typeOfItem,
        "amountWeek": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK")),
        "amountFortnight": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT")),
        "amountMonth": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH")),
        "amountQuarter": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER")),
        "amountYear": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
      }
      incomeTotals.push(budgetLineTotal);
    }

  }

  const totalColumns = [{
    dataField: 'index',
    text: 'index',
    sort: true,
    hidden: true
  }, {
    dataField: 'amountWeek',
    text: 'Week',
    headerStyle: {
      backgroundColor: tableHeaders
    }
  }, {
    dataField: 'amountFortnight',
    text: 'Fortnight',
    headerStyle: {
      backgroundColor: tableHeaders
    }
  }, {
    dataField: 'amountMonth',
    text: 'Month',
    headerStyle: {
      backgroundColor: tableHeaders
    }
  }, {
    dataField: 'amountQuarter',
    text: 'Quarter',
    headerStyle: {
      backgroundColor: tableHeaders
    }
  }, {
    dataField: 'amountYear',
    text: 'Year',
    headerStyle: {
      backgroundColor: tableHeaders
    }
  }];

  let yearBudgetItems = budgetItems.reduce((item, {itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem}) =>
  {
      frequencyOfItem.includes("YEAR") && item.push({itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem});
      return item;
  }, []);

  let quarterBudgetItems = budgetItems.reduce((item, {itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem}) =>
  {
      frequencyOfItem.includes("QUARTER") && item.push({itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem});
      return item;
  }, []);

  let monthBudgetItems = budgetItems.reduce((item, {itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem}) =>
  {
      frequencyOfItem.includes("MONTH") && item.push({itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem});
      return item;
  }, []);

  let fortnightBudgetItems = budgetItems.reduce((item, {itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem}) =>
  {
      frequencyOfItem.includes("FORTNIGHT") && item.push({itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem});
      return item;
  }, []);

  let weekBudgetItems = budgetItems.reduce((item, {itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem}) =>
  {
      frequencyOfItem.includes("WEEK") && item.push({itemId, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem});
      return item;
  }, []);

  return (
    <>
      <h3>Total - Averaged over 12 months</h3>
      <BootstrapTable bootstrap4 keyField='id' data={ incomeTotals } columns={ totalColumns } wrapperClasses="table-responsive" striped hover condensed />
      <LinkContainer to="/budget/income/new">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold">Add a new income</span>
        </ListGroup.Item>
      </LinkContainer>
      <div class="text-center">
          <hr></hr>
          {yearBudgetItems.length > 0 &&
          <>
            <h3>Yearly ({yearBudgetItems.length} items)</h3>
            <ItemListGroupMap items={yearBudgetItems}/>
            <hr></hr>
          </>
          }
          {quarterBudgetItems.length > 0 &&
          <>
            <h3>Quarterly ({quarterBudgetItems.length} items)</h3>
            <ItemListGroupMap items={quarterBudgetItems}/>
            <hr></hr>
          </>
          }
          {monthBudgetItems.length > 0 &&
          <>
            <h3>Monthly ({monthBudgetItems.length} items)</h3>
            <ItemListGroupMap items={monthBudgetItems}/>
            <hr></hr>
          </>
          }
          {fortnightBudgetItems.length > 0 &&
          <>
            <h3>Fortnightly ({fortnightBudgetItems.length} items)</h3>
            <ItemListGroupMap items={fortnightBudgetItems}/>
            <hr></hr>
          </>
          }
          {weekBudgetItems.length > 0 &&
          <>
            <h3>Weekly ({weekBudgetItems.length} items)</h3>
            <ItemListGroupMap items={weekBudgetItems}/>
            <hr></hr>
          </>
          }
        </div>
      </>
  );
}

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Income</h2>
        <ListGroup>{!isLoading && renderBudgetItemsList(budgetItems)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Income">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}