    // Function to format money fields in $xxx,yyy format
    export function moneyFormatter(cell, row) {
      if (row.onSale) {
        return (
          <span>
            <strong style={ { color: 'red' } }>$ { cell } NTD(Sales!!)</strong>
          </span>
        );
      }
    
      return (
        
        <span>${ cell } </span>
      );
    }

    // Determine colours on table
    export const rowStyleTotals = (row) => {
      const style = {};
      if (row.typeTotal === "Leftover" && row.amountYear < 0) {
        style.backgroundColor = "#d9534f";
      } else if (row.typeTotal === "Leftover" && row.amountYear >= 0) {
        style.backgroundColor = " #5cb85c";
      } else {
        //style.backgroundColor = tableRows;
      }

      return style;
    }

    // Default sort for the show all items table
    export const sortAllItems = [{
      dataField: "typeOfItem",
      order: "asc"
    }];

    // Default sort for the show totals table
    export const sortTotals = [{
      dataField: "index",
      order: "asc"
    }];