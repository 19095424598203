import React from "react";

import { Pie } from "react-chartjs-2";

class PercentSubTypePieChart extends React.Component {
constructor(props) {
 super(props);

 this.state = {
   chartData: {
    labels: props.percents.labels,
     datasets: [
       {
         label: "Percentages",
         backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(63, 191, 191, 0.2)',
          'rgba(25, 76, 76, 0.2)',
          'rgba(63, 191, 63, 0.2)',
          'rgba(191, 63, 191, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(63, 191, 191, 1)',
          'rgba(25, 76, 76, 1)',
          'rgba(63, 191, 63, 1)',
          'rgba(191, 63, 191, 1)',
        ],
         data: props.percents.data,
         borderWidth: 1
       }
     ]
   },
   legend: {
    display: false,
    }
 };
}

render() {

const options={
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
    },
  }
};

 return (
 <Pie data={this.state.chartData} height={"200%"} width={"100%"} options={ options }/>
 );
}
}

export default PercentSubTypePieChart;