  const dev = {
    STRIPE_KEY: "pk_test_51JA1wFAthOiyEeyrUFU3DjzEb5KMazUPx0LUtwBtGaf9rsTX60Wf999P4RFbSTP3lUbKngeXCELLj8ll92n0UMOO00WAMw3HWb",
    s3: {
      REGION: "ap-southeast-2",
      BUCKET: "dev-notes-infra-s3-uploads4f6eb0fd-1hcb3zkzb90l9"
    },
    apiGatewayNotes: {
      REGION: "ap-southeast-2",
      URL: "https://0wi7ojah8f.execute-api.ap-southeast-2.amazonaws.com/dev"
    },
    apiGatewayBudget: {
      REGION: "ap-southeast-2",
      URL: "https://0wi7ojah8f.execute-api.ap-southeast-2.amazonaws.com/dev/budget"
    },
    apiGatewayPortfolio: {
      REGION: "ap-southeast-2",
      URL: "https://0wi7ojah8f.execute-api.ap-southeast-2.amazonaws.com/dev/portfolio"
    },
    cognito: {
      REGION: "ap-southeast-2",
      USER_POOL_ID: "ap-southeast-2_TAIw4ESMn",
      APP_CLIENT_ID: "407tv2v5mfs102uvmj3723prq",
      IDENTITY_POOL_ID: "ap-southeast-2:ff570d27-87ee-4a13-a82b-1a08eda98e8f"
    }
  };
  
  const prod = {
    STRIPE_KEY: "pk_test_51JA1wFAthOiyEeyrUFU3DjzEb5KMazUPx0LUtwBtGaf9rsTX60Wf999P4RFbSTP3lUbKngeXCELLj8ll92n0UMOO00WAMw3HWb",
    s3: {
      REGION: "ap-southeast-2",
      BUCKET: "prod-notes-infra-s3-uploads4f6eb0fd-1krqoxsiwp3gl"
    },
    apiGatewayNotes: {
      REGION: "ap-southeast-2",
      URL: "https://p1p61q1ekl.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    apiGatewayBudget: {
      REGION: "ap-southeast-2",
      URL: "https://p1p61q1ekl.execute-api.ap-southeast-2.amazonaws.com/prod/budget"
    },
    apiGatewayPortfolio: {
      REGION: "ap-southeast-2",
      URL: "https://p1p61q1ekl.execute-api.ap-southeast-2.amazonaws.com/prod/portfolio"
    },
    cognito: {
      REGION: "ap-southeast-2",
      USER_POOL_ID: "ap-southeast-2_kWsLLBpoS",
      APP_CLIENT_ID: "28o21b8a0v5iemlpao7cspgtmi",
      IDENTITY_POOL_ID: "ap-southeast-2:d0b83ca8-26da-43a2-a596-773d4471d5a9"
    }
  };
  
  const config = {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    // Default to dev if not set
    ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
  };
  
  export default config;