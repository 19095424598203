import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";
import { onError } from "../../../libs/errorLib";
import "./NewDebitCard.css";
import { API } from "aws-amplify";

export default function NewDebitCard() {
  const history = useHistory();
  const [typeOfItem] = useState("income");
  const [subtypeOfItem, setSubtypeOfItem] = useState("");
  const [descriptionOfItem, setDescriptionOfItem] = useState("");
  const [amountOfItem, setamountOfItem] = useState("");
  const [frequencyOfItem, setfrequencyOfItem] = useState("FORTNIGHT");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return descriptionOfItem.length > 0;
  }

  async function handleSubmit(event) {

    if (frequencyOfItem === ""){
      setfrequencyOfItem("FORTNIGHTLY")
    }


    console.log("Variables:")
    console.log("Name: " + typeOfItem);
    console.log("Name: " + descriptionOfItem);
    console.log("Amount: " + amountOfItem);
    console.log("Frequncy: " + frequencyOfItem);
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
 
      await createBudgetItem({ typeOfItem, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem});
      history.push("/income");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createBudgetItem(budgetItem) {
    return API.post("budget", "/add-item", {
      body: budgetItem
    });
  }

  return (
    <div className="NewCreditCard">
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="subtypeOfItem" size="lg">
          <Form.Label>Type</Form.Label>
          <Form.Control
            autoFocus
            type="subtypeOfItem"
            value={subtypeOfItem}
            placeholder="Type of income"
            onChange={(e) => setSubtypeOfItem(e.target.value)}
          />
        </Form.Group>
      <Form.Group controlId="descriptionOfItem" size="lg">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="descriptionOfItem"
            value={descriptionOfItem}
            placeholder="Description of Income"
            onChange={(e) => setDescriptionOfItem(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="amountOfItem" size="lg">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            autoFocus
            type="amountOfItem"
            value={amountOfItem}
            placeholder="Amount of Income"
            onChange={(e) => setamountOfItem(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="frequencyOfItem" size="lg">
          <Form.Label>Frequency: </Form.Label>
          <select value={frequencyOfItem} onChange={(val) => setfrequencyOfItem(val.target.value)} className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <option value="WEEK">Weekly</option>
            <option value="FORTNIGHT" >Fortnightly</option>
            <option value="MONTH">Monthly</option>
            <option value="QUARTER">Quarterly</option>
            <option value="YEAR">Yearly</option>
          </select>
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}