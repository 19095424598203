import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";
import { onError } from "../../../libs/errorLib";
import "./NewLiability.css";
import { API } from "aws-amplify";

export default function NewLiability() {

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0,10);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [typeOfLiability, setTypeOfLiability] = useState("");
  const [liabilityActive, setLiabilityActive] = useState(false);
  const [liabilityName, setLiabilityName] = useState("");
  const [liabilityNote, setLiabilityNote] = useState("");
  const [liabilityStartDate, setLiabilityStartDate] = useState("");
  const [liabilityStartAmount, setLiabilityStartAmount] = useState("");
  const [liabilityMinRepayment, setLiabilityMinRepayment] = useState("");
  const [liabilityMinRepaymentFrequency, setLiabilityMinRepaymentFrequency] = useState("");
  const [liabilityCurrentDate, setLiabilityCurrentDate] = useState(date);
  const [liabilityCurrentAmount, setLiabilityCurrentAmount] = useState("");
  const [liabilityCurrentRepayment, setLiabilityCurrentRepayment] = useState("");
  const [liabilityCurrentRepaymentFrequency, setLiabilityCurrentRepaymentFrequency] = useState("");
  const [liabilityNextRepayment, setLiabilityNextRepayment] = useState("");
  const [liabilityInterestRate, setLiabilityInterestRate] = useState("");
  const [liabilityInterestFrequency, setLiabilityInterestFrequency] = useState("");
  const [liabilityFee, setLiabilityFee] = useState("");
  const [liabilityFeeFrequency, setLiabilityFeeFrequency] = useState("");

  const handleOnChange = () => {
    setLiabilityActive(!liabilityActive);
  };

  function validateForm() {
    return liabilityNote.length > 0;
  }

  async function handleSubmit(event) {

    event.preventDefault();
  
    setIsLoading(true);
  
    try {
 
      await createBudgetItem({ typeOfLiability, liabilityActive, liabilityName, liabilityNote, liabilityStartDate, liabilityStartAmount, liabilityCurrentDate, liabilityCurrentAmount, liabilityCurrentRepayment, liabilityCurrentRepaymentFrequency, liabilityNextRepayment, liabilityInterestRate, liabilityInterestFrequency, liabilityFee, liabilityFeeFrequency});
      history.push("/portfolio/liabilities");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createBudgetItem(budgetItem) {
    return API.post("portfolio", "/liabilities/add", {
      body: budgetItem
    });
  }

  return (
    <div className="NewAsset">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Create new liability</h2>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="liabilityStartDate" size="lg">
          <Form.Label>Start date of Liability</Form.Label>
          <Form.Control 
            type="date" 
            name='liabilityStartDate'
            defaultValue={liabilityStartDate}
            onChange={(e) => setLiabilityStartDate(e.target.value)}
            />
      </Form.Group>
      <Form.Group controlId="liabilityCurrentDate" size="lg">
          <Form.Label>Current Date of Liability</Form.Label>
          <Form.Control 
            type="date" 
            name='liabilityCurrentDate'
            defaultValue={liabilityCurrentDate}
            onChange={(e) => setLiabilityCurrentDate(e.target.value)}
            />
      </Form.Group>
      <Form.Group controlId="subtypeOfItem" size="lg">
          <Form.Label>Type of Liability</Form.Label>
          <Form.Control
            autoFocus
            type="typeOfLiability"
            value={typeOfLiability}
            placeholder="Type of Liability"
            onChange={(e) => setTypeOfLiability(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityName" size="lg">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="liabilityName"
            value={liabilityName}
            placeholder="Name"
            onChange={(e) => setLiabilityName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityNote" size="lg">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="liabilityNote"
            value={liabilityNote}
            placeholder="Note"
            onChange={(e) => setLiabilityNote(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityStartAmount" size="lg">
          <Form.Label>Start Amount</Form.Label>
          <Form.Control
            type="liabilityStartAmount"
            value={liabilityStartAmount}
            placeholder="Starting Amount"
            onChange={(e) => setLiabilityStartAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityMinRepayment" size="lg">
          <Form.Label>Min Repayment</Form.Label>
          <Form.Control
            type="liabilityMinRepayment"
            value={liabilityMinRepayment}
            placeholder="Min Repayment"
            onChange={(e) => setLiabilityMinRepayment(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityMinRepaymentFrequency" size="lg">
          <Form.Label>Min Repayment Frequency</Form.Label>
          <Form.Control
            type="liabilityMinRepaymentFrequency"
            value={liabilityMinRepaymentFrequency}
            placeholder="Min Repayment Frequency"
            onChange={(e) => setLiabilityMinRepaymentFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityCurrentAmount" size="lg">
          <Form.Label>Current Amount</Form.Label>
          <Form.Control
            type="liabilityCurrentAmount"
            value={liabilityCurrentAmount}
            placeholder="Current Repayment"
            onChange={(e) => setLiabilityCurrentAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityCurrentRepayment" size="lg">
          <Form.Label>Current Repayment</Form.Label>
          <Form.Control
            type="liabilityCurrentRepayment"
            value={liabilityCurrentRepayment}
            placeholder="Current Repayment"
            onChange={(e) => setLiabilityCurrentRepayment(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityCurrentRepaymentFrequency" size="lg">
          <Form.Label>Current Repayment Frequency</Form.Label>
          <Form.Control
            type="liabilityCurrentRepaymentFrequency"
            value={liabilityCurrentRepaymentFrequency}
            placeholder="Current Repayment"
            onChange={(e) => setLiabilityCurrentRepaymentFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityNextRepayment" size="lg">
          <Form.Label>Next Repayment Due</Form.Label>
          <Form.Control 
            type="date" 
            name='liabilityNextRepayment'
            defaultValue={liabilityNextRepayment}
            onChange={(e) => setLiabilityNextRepayment(e.target.value)}
            />
        </Form.Group>
        <Form.Group controlId="liabilityInterestRate" size="lg">
          <Form.Label>Interest Rate</Form.Label>
          <Form.Control
            type="liabilityInterestRate"
            value={liabilityInterestRate}
            placeholder="Interest Rate"
            onChange={(e) => setLiabilityInterestRate(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityInterestFrequency" size="lg">
          <Form.Label>Interest Applied Frequency</Form.Label>
          <Form.Control
            type="liabilityInterestFrequency"
            value={liabilityInterestFrequency}
            placeholder="Interest Applied Frequency"
            onChange={(e) => setLiabilityInterestFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityFee" size="lg">
          <Form.Label>Fees</Form.Label>
          <Form.Control
            type="liabilityFee"
            value={liabilityFee}
            placeholder="Fees"
            onChange={(e) => setLiabilityFee(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityFeeFrequency" size="lg">
          <Form.Label>Fee Applied Frequency</Form.Label>
          <Form.Control
            type="liabilityFeeFrequency"
            value={liabilityFeeFrequency}
            placeholder="Fee Applied Frequency"
            onChange={(e) => setLiabilityFeeFrequency(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="liabilityActive" size="lg">
          <Form.Label>Liability Active</Form.Label>
          <Form.Check
            type="checkbox"
            id="liabilityActive"
            label="Active"
            checked={liabilityActive}
            onChange={handleOnChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}