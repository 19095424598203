    import * as TableConstants from "./constants"

    export const tableHeaders = "#8c96d7";

    // Totals Table columns
    export const totalColumns = [{
        dataField: "index",
        text: "index",
        sort: true,
        hidden: true,
      }, {
        dataField: "typeTotal",
        text: "Type",
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "amountWeekPretty",
        text: "Week",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountFortnightPretty",
        text: "Fortnight",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountMonthPretty",
        text: "Month",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountQuarterPretty",
        text: "Quarter",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }, {
        dataField: "amountYearPretty",
        text: "Year",
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
      }];
    
    // Columns o show for savings sub type table
    export const savingSubTypeColumns = [{
        dataField: "subtype",
        text: "Type",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "percentageText",
        text: "Percentage",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }];
  
      // Columns for table showing individual items
      export const itemColumns = [{
        dataField: "typeOfItem",
        text: "Type",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "subtypeOfItem",
        text: "Subtype",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }, {
        dataField: "descriptionOfItem",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "amountOfItem",
        text: "Amount",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
        formatter: TableConstants.moneyFormatter,
        sortFunc: (a, b, order) => {
          const numA = parseFloat(a.replace(/,/g,''), 10)
          const numB = parseFloat(b.replace(/,/g,''), 10)
          if (order === "asc") {
              return numB - numA;
          }
          return numA - numB;
        }
      }, {
        dataField: "frequencyOfItem",
        text: "Frequency",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        }
      }];
  
      // Income Sub Type Table columns
      export const incomeSubTypeColumns = [{
        dataField: "subtype",
        text: "Type",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "percentageText",
        text: "Percentage",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }];
  
      // Expense Sub Type Table columns
      export const expenseSubTypeColumns = [{
        dataField: "subtype",
        text: "Type",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "percentageText",
        text: "Percentage",
        sort: true,
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }];

      // Percent Type Columns
      export const percentageTypeColumns = [{
        dataField: "Savings",
        text: "Savings",
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "Expenses",
        text: "Expenses",
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }, {
        dataField: "Leftover",
        text: "Leftover",
        headerStyle: {
          backgroundColor: tableHeaders
        },
      }];