import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../../../libs/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../../../components/LoaderButton";
import "./Asset.css";
import { useAppContext } from "../../../../libs/contextLib";
import ListGroup from "react-bootstrap/ListGroup";

export default function Asset() {
  const { id } = useParams();
  const history = useHistory();
  const { isAuthenticated } = useAppContext();
  const [assetDate, setAssetDate] = useState("");
  const [typeOfAsset, setTypeOfAsset] = useState("");
  const [assetActive, setAssetActive] = useState("");
  const [assetNote, setAssetNote] = useState("");
  const [assetName, setAssetName] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetQuantity, setAssetQuantity] = useState("");
  const [assetSellPrice, setAssetSellPrice] = useState("");
  const [budgetItem, setBudgetItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnChange = () => {
    setAssetActive(!assetActive);
  };

  useEffect(() => {
    function loadBudgetItem() {
      return API.get("portfolio", `/assets/${id}`);
    }

    async function onLoad() {
      try {
        const budgetItem = await loadBudgetItem();
        const { assetActive, assetDate, typeOfAsset, assetNote, assetName, assetCode, assetQuantity, assetSellPrice } = budgetItem;


        setAssetActive(assetActive);
        setAssetDate(assetDate);
        setTypeOfAsset(typeOfAsset);
        setAssetNote(assetNote);
        setAssetName(assetName);
        setAssetCode(assetCode);
        setAssetQuantity(assetQuantity);
        setAssetSellPrice(assetSellPrice);

        setBudgetItem(budgetItem);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return typeOfAsset.length > 0;
  }
  
  function saveBudgetItem(budgetItem) {
    return API.put("portfolio", `/assets/${id}`, {
      body: budgetItem
    });
  }
  
  async function handleSubmit(event) {
  
    event.preventDefault();
  
    setIsLoading(true);

    var assetTotalPrice = assetQuantity * assetSellPrice;
  
    try {
 
      await saveBudgetItem({
        assetActive,
        assetDate,
        typeOfAsset,
        assetNote,
        assetName,
        assetCode,
        assetQuantity,
        assetSellPrice,
        assetTotalPrice,
      });

      history.push("/portfolio/assets");

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteBudgetItem() {
    return API.del("portfolio", `/assets/${id}`);
  }
  
  async function handleInsert(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to insert new entry for this asset?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteBudgetItem();
        history.push("/portfolio/assets");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteBudgetItem();
        history.push("/portfolio/assets");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function renderBudgetItemsList() {

  return (
    <div className="Items">
      {budgetItem && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="assetDate" size="lg">
          <Form.Label>Date of Asset</Form.Label>
          <Form.Control 
            type="date" 
            name='assetDate'
            defaultValue={assetDate}
            placeholder={assetDate}
            onChange={(e) => setAssetDate(e.target.value)}
            />
        </Form.Group>
          <Form.Group controlId="subtypeOfItem" size="lg">
              <Form.Label>Type of Asset</Form.Label>
              <Form.Control
                autoFocus
                type="typeOfAsset"
                value={typeOfAsset}
                placeholder="Type of Asset"
                onChange={(e) => setTypeOfAsset(e.target.value)}
              />
            </Form.Group>
          <Form.Group controlId="assetNote" size="lg">
              <Form.Label>Note</Form.Label>
              <Form.Control
                type="assetNote"
                value={assetNote}
                placeholder="Notes of asset"
                onChange={(e) => setAssetNote(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="assetName" size="lg">
              <Form.Label>Asset Name</Form.Label>
              <Form.Control
                type="assetName"
                value={assetName}
                placeholder="Asset Name"
                onChange={(e) => setAssetName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="assetCode" size="lg">
              <Form.Label>Asset Code</Form.Label>
              <Form.Control
                type="assetCode"
                value={assetCode}
                placeholder="Asset Code"
                onChange={(e) => setAssetCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="assetQuantity" size="lg">
              <Form.Label>Asset Quantity</Form.Label>
              <Form.Control
                type="assetQuantity"
                value={assetQuantity}
                placeholder="Asset Quantity"
                onChange={(e) => setAssetQuantity(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="assetSellPrice" size="lg">
              <Form.Label>Asset Sell Price</Form.Label>
              <Form.Control
                type="assetSellPrice"
                value={assetSellPrice}
                placeholder="Asset Sell Price"
                onChange={(e) => setAssetSellPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="assetActive" size="lg">
          <Form.Label>Asset Active</Form.Label>
          <Form.Check
            type="checkbox"
            id="assetActive"
            label="Active"
            checked={assetActive}
            onChange={handleOnChange}
          />
        </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Modify Snapshot
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="info"
            isLoading={isLoading}
            onClick={handleInsert}
            disabled={!validateForm()}
          >
            Insert New Snapshot
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete Snapshot
          </LoaderButton>
        </Form>
      )}
    </div>
  );
}

function renderLander() {
  return (
    <div className="lander">
      <h1>Making Budgets</h1>
      <p className="text-muted">A simple tool for making budgets</p>
    </div>
  );
}

function renderBudgetItems() {
  return (
    <div className="budgetItems">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Edit Asset</h2>
      <ListGroup>{!isLoading && renderBudgetItemsList()}</ListGroup>
    </div>
  );
}

return (
  <div className="Asset">
    {isAuthenticated ? renderBudgetItems() : renderLander()}
  </div>
);
}