import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
//import Table from 'react-bootstrap/Table'
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import "./Overview.css";
import { API } from "aws-amplify";
import { calculateTotals } from "../../../libs/calculateTotals";
import {isMobile} from 'react-device-detect';

export default function CardOverview() {
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  //var tableHeaders = "#8c96d7";
  //var tableRows = "#91acda";

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadBudgetItems() {
    return API.get("budget", "/list-items");
  }


  
  function renderBudgetItemsList(budgetItems) {

    if (isMobile) { require('./custom.css'); }

    var objIndex;

    let budgetTotals = [];

    let budgetTotalPercentages = [];

    let incomeSubtypes = [];
    let savingSubtypes = [];
    let expenseSubtypes = [];

    for (let budgetItem of budgetItems) {

      var index;
      var budgetTotalsIndex;
      let typeText;

      if (budgetItem.typeOfItem === "income"){
        index = 1;
        typeText = "Income";
      } else if (budgetItem.typeOfItem === "savings"){
        index = 2;
        typeText = "Savings";
      } else if (budgetItem.typeOfItem === "expense"){
        index = 3;
        typeText = "Expenses";
      }

      // Populate array in the format
      // index = 1
      // type = income
      // amountWeek = 100
      // amountFortnight = 200
      // amountMonth = 400
      // amountQuarter = 1300
      // amountYear = 5200
      if (budgetTotals.some(budgetLineTotal => budgetLineTotal.typeTotal === typeText)){
        budgetTotalsIndex = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === typeText);
        budgetTotals[budgetTotalsIndex].amountWeek = budgetTotals[budgetTotalsIndex].amountWeek + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK"))
        budgetTotals[budgetTotalsIndex].amountFortnight = budgetTotals[budgetTotalsIndex].amountFortnight + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT"))
        budgetTotals[budgetTotalsIndex].amountMonth = budgetTotals[budgetTotalsIndex].amountMonth + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH"))
        budgetTotals[budgetTotalsIndex].amountQuarter = budgetTotals[budgetTotalsIndex].amountQuarter + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER"))
        budgetTotals[budgetTotalsIndex].amountYear = budgetTotals[budgetTotalsIndex].amountYear + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
      } else {
        let budgetLineTotal = {
          "index": index,
          "typeTotal": typeText,
          "amountWeek": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK")),
          "amountFortnight": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT")),
          "amountMonth": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH")),
          "amountQuarter": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER")),
          "amountYear": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        }
        if (typeText === "Income"){
          budgetTotals.unshift(budgetLineTotal);
        } else {
        budgetTotals.push(budgetLineTotal);
        }
      }

      if (budgetItem.typeOfItem === "income"){

        if (incomeSubtypes.some(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem)){
          objIndex = incomeSubtypes.findIndex(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem);
          incomeSubtypes[objIndex].totalAmount = incomeSubtypes[objIndex].totalAmount + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        } else {
          let subTypeItem = {
            "subtype": budgetItem.subtypeOfItem,
            "totalAmount": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
          }
          incomeSubtypes.push(subTypeItem);
        }

      } else if (budgetItem.typeOfItem === "savings") {

        if (savingSubtypes.some(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem)){
          objIndex = savingSubtypes.findIndex(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem);
          savingSubtypes[objIndex].totalAmount = savingSubtypes[objIndex].totalAmount + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        } else {
          let subTypeItem = {
            "subtype": budgetItem.subtypeOfItem,
            "totalAmount": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
          }
          savingSubtypes.push(subTypeItem);
        }

      } else if (budgetItem.typeOfItem === "expense") {

        if (expenseSubtypes.some(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem)){
          objIndex = expenseSubtypes.findIndex(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem);
          expenseSubtypes[objIndex].totalAmount = expenseSubtypes[objIndex].totalAmount + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        } else {
          let subTypeItem = {
            "subtype": budgetItem.subtypeOfItem,
            "totalAmount": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
          }
          expenseSubtypes.push(subTypeItem);
        }

      }

    }

    // Work out leftover amounts, push into budgetTotals array
    let budgetLeftOverLineTotal = {
      "index": "4",
      "typeTotal": "Leftover",
      "amountWeek": parseInt(budgetTotals[0].amountWeek - budgetTotals[1].amountWeek - budgetTotals[2].amountWeek),
      "amountFortnight": parseInt(budgetTotals[0].amountFortnight - budgetTotals[1].amountFortnight - budgetTotals[2].amountFortnight),
      "amountMonth": parseInt(budgetTotals[0].amountMonth - budgetTotals[1].amountMonth - budgetTotals[2].amountMonth),
      "amountQuarter": parseInt(budgetTotals[0].amountQuarter - budgetTotals[1].amountQuarter - budgetTotals[2].amountQuarter),
      "amountYear": parseInt(budgetTotals[0].amountYear - budgetTotals[1].amountYear - budgetTotals[2].amountYear)
    }
    budgetTotals.push(budgetLeftOverLineTotal);

    // Lets find where our things are in the index
    var incomeIndex = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Income");
    var expensesIndex  = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Expenses");
    var savingsIndex  = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Savings");
    var leftoverIndex  = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Leftover");


    // Work out percentages
    incomeSubtypes.forEach(incomeSubType => {
      incomeSubType['percentage'] = parseFloat(incomeSubType.totalAmount / budgetTotals[incomeIndex].amountYear * 100).toFixed(2) + "%";
    });

    savingSubtypes.forEach(savingSubtypes => {
      savingSubtypes['percentage'] = parseFloat(savingSubtypes.totalAmount / budgetTotals[savingsIndex].amountYear * 100).toFixed(2) + "%";
    });

    expenseSubtypes.forEach(expenseSubtypes => {
      expenseSubtypes['percentage'] = parseFloat(expenseSubtypes.totalAmount / budgetTotals[expensesIndex].amountYear * 100).toFixed(2) + "%";
    });

    let budgetTypePercentagesTotal = {
      "index": "0",
      "Savings": parseFloat(parseInt(budgetTotals[savingsIndex].amountYear) / parseInt(budgetTotals[incomeIndex].amountYear) * 100).toFixed(2) + "%",
      "Expenses": parseFloat(parseInt(budgetTotals[expensesIndex].amountYear) / parseInt(budgetTotals[incomeIndex].amountYear) * 100).toFixed(2) + "%",
      "Leftover": parseFloat(parseInt(budgetTotals[leftoverIndex].amountYear) / parseInt(budgetTotals[incomeIndex].amountYear) * 100).toFixed(2) + "%",
    }
    budgetTotalPercentages.push(budgetTypePercentagesTotal);

//    var rowTotalColour = "table-warning";

    //if (parseInt(budgetTotals[leftoverIndex].amountWeek) >= 0) {
       //rowTotalColour = "table-success";
    //} else {
//      rowTotalColour = "table-danger";
    //}

    return (
      <>      

        <h3>Cards</h3>

        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Card Overview</h2>
        <ListGroup>{!isLoading && renderBudgetItemsList(budgetItems)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Overview">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}