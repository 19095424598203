import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import Verify from "./containers/Verify";
import Notes from "./containers/Notes";
import Overview from "./containers/Budget/Overview/Overview";
import Expenses from "./containers/Budget/Expenses/Expenses";
import NewExpense from "./containers/Budget/Expenses/NewExpense";
import Income from "./containers/Budget/Income/Income";
import NewIncome from "./containers/Budget/Income/NewIncome";
import Savings from "./containers/Budget/Savings/Savings";
import NewSaving from "./containers/Budget/Savings/NewSaving";
import Items from "./containers/Budget/Items/Items";
import Assets from "./containers/Portfolio/Assets/Assets";
import NewAsset from "./containers/Portfolio/Assets/NewAsset";

import Settings from "./containers/Settings";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// Portfolio imports
// Overview
import PortfolioOverview from "./containers/Portfolio/Overview/Overview";
// Assets
import Asset from "./containers/Portfolio/Assets/Asset/Asset";
// Liabilities
import Liabilities from "./containers/Portfolio/Liabilities/Liabilities";
import NewLiability from "./containers/Portfolio/Liabilities/NewLiability";
import Liability from "./containers/Portfolio/Liabilities/Liability/Liability"

// Credit Card section imports
import CardOverview from "./containers/Cards/Overview/Overview"
import CreditCards from "./containers/Cards/Credit/CreditCards";
import NewCreditCard from "./containers/Cards/Credit/NewCreditCard";
import DebitCards from "./containers/Cards/Debit/DebitCards";
import NewDebitCard from "./containers/Cards/Debit/NewDebitCard";
import RewardsCards from "./containers/Cards/Rewards/RewardsCards";
import NewRewardsCard from "./containers/Cards/Rewards/NewRewardsCard";

// Tools
import PayCalculator from "./containers/Tools/PayCalculator/PayCalculator";
import PayRates from "./containers/Tools/PayRates/PayRates";

import SubMenu from "./containers/SubMenu";

export default function Routes() {
  
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>

      <Route exact path="/verify">
        <Verify />
      </Route>

      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/notes/new">
        <NewNote />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/notes/:id">
        <Notes />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget">
        <SubMenu />
        <Overview />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/overview">
        <SubMenu />
        <Overview />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/expenses">
        <SubMenu />
        <Expenses />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/expenses/new">
        <SubMenu />
        <NewExpense />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/income">
        <SubMenu />
        <Income />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/income/new">
        <SubMenu />
        <NewIncome />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/savings">
        <SubMenu />
        <Savings />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/savings/new">
        <SubMenu />
        <NewSaving />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/budget/items/:id">
        <SubMenu />
        <Items />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio">
        <SubMenu />
        <PortfolioOverview />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/overview">
        <SubMenu />
        <PortfolioOverview />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/assets">
        <SubMenu />
        <Assets />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/assets/new">
        <SubMenu />
        <NewAsset />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/assets/:id">
        <SubMenu />
        <Asset />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/liabilities">
        <SubMenu />
        <Liabilities />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/liabilities/new">
        <SubMenu />
        <NewLiability />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/portfolio/liabilities/:id">
        <SubMenu />
        <Liability />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards">
        <SubMenu />
        <CardOverview />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/overview">
        <SubMenu />
        <CardOverview />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/credit">
        <SubMenu />
        <CreditCards />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/credit/new">
        <SubMenu />
        <NewCreditCard />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/debit">
        <SubMenu />
        <DebitCards />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/debit/new">
        <SubMenu />
        <NewDebitCard />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/rewards">
        <SubMenu />
        <RewardsCards />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards/rewards/new">
        <SubMenu />
        <NewRewardsCard />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/tools">
        <SubMenu />
        <PayCalculator />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/tools/paycalculator">
        <SubMenu />
        <PayCalculator />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/tools/payrates">
        <SubMenu />
        <PayRates />
      </AuthenticatedRoute>

    {/* Finally, catch all unmatched routes */}
    <Route>
        <NotFound />
    </Route>

    </Switch>
  );
}