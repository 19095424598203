import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
//import Table from "react-bootstrap/Table"
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import "./Overview.css";
import { API } from "aws-amplify";
import { calculateTotals } from "../../../libs/calculateTotals";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search, ColumnToggle } from "react-bootstrap-table2-toolkit";
import {isMobile} from "react-device-detect";
import PercentPieChart from "./Charts/PercentPieChart";
import PercentSubTypePieChart from "./Charts/PercentSubTypePieChart";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import * as TableConstants from "./Tables/constants.js";
import * as TableColumns from "./Tables/columns.js";

export default function Overview() {
  const [budgetItems, setBudgetItems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { ToggleList } = ColumnToggle;
  const { SearchBar, ClearSearchButton } = Search;

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const budgetItems = await loadBudgetItems();
        setBudgetItems(budgetItems);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadBudgetItems() {
    return API.get("budget", "/list-items");
  }


  
  function renderBudgetItemsList(budgetItems) {

    if (isMobile) { require("./custom.css"); }

    var objIndex;

    let budgetTotals = [];

    let budgetTotalPercentages = [];

    let incomeSubtypes = [];
    let savingSubtypes = [];
    let expenseSubtypes = [];

    for (let budgetItem of budgetItems) {

      var index;
      var budgetTotalsIndex;
      let typeText;

      if (budgetItem.typeOfItem === "income"){
        index = 1;
        typeText = "Income";
      } else if (budgetItem.typeOfItem === "savings"){
        index = 2;
        typeText = "Savings";
      } else if (budgetItem.typeOfItem === "expense"){
        index = 3;
        typeText = "Expenses";
      }

      // Populate array in the format
      // index = 1
      // type = income
      // amountWeek = 100
      // amountFortnight = 200
      // amountMonth = 400
      // amountQuarter = 1300
      // amountYear = 5200
      if (budgetTotals.some(budgetLineTotal => budgetLineTotal.typeTotal === typeText)){
        budgetTotalsIndex = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === typeText);
        budgetTotals[budgetTotalsIndex].amountWeek = budgetTotals[budgetTotalsIndex].amountWeek + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK"))
        budgetTotals[budgetTotalsIndex].amountFortnight = budgetTotals[budgetTotalsIndex].amountFortnight + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT"))
        budgetTotals[budgetTotalsIndex].amountMonth = budgetTotals[budgetTotalsIndex].amountMonth + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH"))
        budgetTotals[budgetTotalsIndex].amountQuarter = budgetTotals[budgetTotalsIndex].amountQuarter + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER"))
        budgetTotals[budgetTotalsIndex].amountYear = budgetTotals[budgetTotalsIndex].amountYear + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
      } else {
        let budgetLineTotal = {
          "index": index,
          "typeTotal": typeText,
          "amountWeek": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "WEEK")),
          "amountFortnight": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "FORTNIGHT")),
          "amountMonth": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "MONTH")),
          "amountQuarter": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "QUARTER")),
          "amountYear": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        }
        if (typeText === "Income"){
          budgetTotals.unshift(budgetLineTotal);
        } else {
        budgetTotals.push(budgetLineTotal);
        }
      }

      if (budgetItem.typeOfItem === "income"){

        if (incomeSubtypes.some(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem)){
          objIndex = incomeSubtypes.findIndex(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem);
          incomeSubtypes[objIndex].totalAmount = incomeSubtypes[objIndex].totalAmount + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        } else {
          let subTypeItem = {
            "subtype": budgetItem.subtypeOfItem,
            "totalAmount": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
          }
          incomeSubtypes.push(subTypeItem);
        }

      } else if (budgetItem.typeOfItem === "savings") {

        if (savingSubtypes.some(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem)){
          objIndex = savingSubtypes.findIndex(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem);
          savingSubtypes[objIndex].totalAmount = savingSubtypes[objIndex].totalAmount + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        } else {
          let subTypeItem = {
            "subtype": budgetItem.subtypeOfItem,
            "totalAmount": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
          }
          savingSubtypes.push(subTypeItem);
        }

      } else if (budgetItem.typeOfItem === "expense") {

        if (expenseSubtypes.some(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem)){
          objIndex = expenseSubtypes.findIndex(subTypeItem => subTypeItem.subtype === budgetItem.subtypeOfItem);
          expenseSubtypes[objIndex].totalAmount = expenseSubtypes[objIndex].totalAmount + parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
        } else {
          let subTypeItem = {
            "subtype": budgetItem.subtypeOfItem,
            "totalAmount": parseInt(calculateTotals(budgetItem.amountOfItem, budgetItem.frequencyOfItem, "YEAR"))
          }
          expenseSubtypes.push(subTypeItem);
        }

      }

    }

    // Work out leftover amounts, push into budgetTotals array
    let budgetLeftOverLineTotal = {
      "index": "4",
      "typeTotal": "Leftover",
      "amountWeek": parseInt(budgetTotals[0].amountWeek - budgetTotals[1].amountWeek - budgetTotals[2].amountWeek),
      "amountFortnight": parseInt(budgetTotals[0].amountFortnight - budgetTotals[1].amountFortnight - budgetTotals[2].amountFortnight),
      "amountMonth": parseInt(budgetTotals[0].amountMonth - budgetTotals[1].amountMonth - budgetTotals[2].amountMonth),
      "amountQuarter": parseInt(budgetTotals[0].amountQuarter - budgetTotals[1].amountQuarter - budgetTotals[2].amountQuarter),
      "amountYear": parseInt(budgetTotals[0].amountYear - budgetTotals[1].amountYear - budgetTotals[2].amountYear)
    }
    budgetTotals.push(budgetLeftOverLineTotal);

    budgetTotals.forEach(type => {
      type["amountWeekPretty"] = parseFloat(type["amountWeek"]).toLocaleString();
      type["amountFortnightPretty"] = parseFloat(type["amountFortnight"]).toLocaleString();
      type["amountMonthPretty"] = parseFloat(type["amountMonth"]).toLocaleString();
      type["amountQuarterPretty"] = parseFloat(type["amountQuarter"]).toLocaleString();
      type["amountYearPretty"] = parseFloat(type["amountYear"]).toLocaleString();
    });

    // Lets find where our things are in the index
    var incomeIndex = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Income");
    var expensesIndex  = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Expenses");
    var savingsIndex  = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Savings");
    var leftoverIndex  = budgetTotals.findIndex(budgetLineTotal => budgetLineTotal.typeTotal === "Leftover");


    // Work out percentages
    incomeSubtypes.forEach(incomeSubType => {
      incomeSubType["percentage"] = parseFloat(incomeSubType.totalAmount / budgetTotals[incomeIndex].amountYear * 100).toFixed(2);
      incomeSubType["percentageText"] = incomeSubType.percentage + "%";
    });

    savingSubtypes.forEach(savingSubtypes => {
      savingSubtypes["percentage"] = parseFloat(savingSubtypes.totalAmount / budgetTotals[savingsIndex].amountYear * 100).toFixed(2);
      savingSubtypes["percentageText"] = savingSubtypes.percentage + "%";
    });

    expenseSubtypes.forEach(expenseSubtypes => {
      expenseSubtypes["percentage"] = parseFloat(expenseSubtypes.totalAmount / budgetTotals[expensesIndex].amountYear * 100).toFixed(2);
      expenseSubtypes["percentageText"] = expenseSubtypes.percentage + "%";
    });

    console.log(incomeSubtypes);

    var savingsPercent = parseFloat(parseInt(budgetTotals[savingsIndex].amountYear) / parseInt(budgetTotals[incomeIndex].amountYear) * 100).toFixed(2);
    var expensesPercent = parseFloat(parseInt(budgetTotals[expensesIndex].amountYear) / parseInt(budgetTotals[incomeIndex].amountYear) * 100).toFixed(2);
    var leftoverPercent = parseFloat(parseInt(budgetTotals[leftoverIndex].amountYear) / parseInt(budgetTotals[incomeIndex].amountYear) * 100).toFixed(2);

    // Building arrays to be passed into PercentSubTypePieChart
    var percentOfIncomeSubtype = {
      labels: [],
      data: [],
    }

    incomeSubtypes.forEach(incomeSubType => {
      percentOfIncomeSubtype.labels.push(incomeSubType.subtype);
      percentOfIncomeSubtype.data.push(incomeSubType.percentage);
    });

    var percentOfSavingsSubtype = {
      labels: [],
      data: [],
    }

    savingSubtypes.forEach(savingSubType => {
      percentOfSavingsSubtype.labels.push(savingSubType.subtype);
      percentOfSavingsSubtype.data.push(savingSubType.percentage);
    });

    var percentOfExpensesSubtype = {
      labels: [],
      data: [],
    }

    expenseSubtypes.forEach(expenseSubtype => {
      percentOfExpensesSubtype.labels.push(expenseSubtype.subtype);
      percentOfExpensesSubtype.data.push(expenseSubtype.percentage);
    });

    let percentOfIncomePieChart = {
      labels: ["Savings", "Expenses", "Leftover"],
      data: [savingsPercent, expensesPercent, leftoverPercent]
    }

    let budgetTypePercentagesTotal = {
      "index": "0",
      "Savings": savingsPercent + "%",
      "Expenses": expensesPercent + "%",
      "Leftover": leftoverPercent + "%",
    }
    budgetTotalPercentages.push(budgetTypePercentagesTotal);

    return (
      <>      
        <h3>Budget Total - Averaged over 12 months</h3>

        <BootstrapTable 
          bootstrap4
          keyField="id"
          defaultSorted={ TableConstants.sortTotals }
          data={ budgetTotals }
          columns={ TableColumns.totalColumns }
          wrapperClasses="table-responsive"
          striped
          hover
          condensed
          rowStyle={ TableConstants.rowStyleTotals }
        />

        <div>
          <hr/>
        </div>

        <h3 class="text-center">Proportion of Income</h3>

        <div class="row" >
          <div class="col-md">
            <PercentPieChart percents={percentOfIncomePieChart}/>
           </div>
        </div>

        <BootstrapTable 
          bootstrap4
          keyField="id"
          data={ budgetTotalPercentages }
          columns={ TableColumns.percentageTypeColumns }
          wrapperClasses="table-responsive"
          striped
          hover
          condensed 
        />

        <div>
          <hr/>
        </div>
        
        <h3 class="text-center">Distribution</h3>
        <div class="row" >
           <div class="col-sm">
             <div>
               <h3 class="text-center">Income</h3>
             </div>
             <div>
                <PercentSubTypePieChart 
                  percents={percentOfIncomeSubtype}
                />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ incomeSubtypes }
                 columns={ TableColumns.incomeSubTypeColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
           <div class="col-sm">
             <div>
               <h3 class="text-center">Savings</h3>
             </div>
             <div>
               <PercentSubTypePieChart 
                 percents={percentOfSavingsSubtype}
               />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ savingSubtypes }
                 columns={ TableColumns.savingSubTypeColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
           <div class="col-sm">
             <div>
               <h3 class="text-center">Expenses</h3>
             </div>
             <div>
               <PercentSubTypePieChart 
                 percents={percentOfExpensesSubtype}
               />
             </div>
             <div>
               <BootstrapTable
                 bootstrap4
                 keyField="id"
                 data={ expenseSubtypes }
                 columns={ TableColumns.expenseSubTypeColumns }
                 wrapperClasses="table-responsive"
                 striped
                 hover
                 condensed
               />
             </div>
           </div>
        </div>

        <div>
          <hr></hr>
        </div>

        <Button
        onClick={() => setOpen(!open)}
        aria-controls="collapse-budget-items"
        aria-expanded={open}
        >
        Toggle viewing budget items
        </Button>

        <Collapse in={open}>
          <div id="collapse-budget-items">
        <ToolkitProvider
          keyField="id"
          data={ budgetItems }
          columns={ TableColumns.itemColumns }
          search
          columnToggle
        >
          {props => (
            <div>
              <hr></hr>
              <h3>Budget Items</h3>
              <ToggleList { ...props.columnToggleProps } style={{ float: "right" }} />
              &nbsp;&nbsp;&nbsp;
              <SearchBar
                {...props.searchProps}
                style={{ width: "200px", height: "40px" }}
              />
            <ClearSearchButton { ...props.searchProps }/>

            <BootstrapTable
              bootstrap4
              keyField="id"
              defaultSorted={ TableConstants.sortAllItems }
              data={ budgetItems }
              columns={ TableColumns.itemColumns }
              wrapperClasses="table-responsive"
              striped
              hover
              condensed
              {...props.baseProps }
             />
            </div>
          )}
        </ToolkitProvider>
        </div>
        </Collapse>

        <hr></hr>

        </>

    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Making Budgets</h1>
        <p className="text-muted">A simple tool for making budgets</p>
      </div>
    );
  }

  function renderBudgetItems() {
    return (
      <div className="budgetItems">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Budget Overview</h2>
        <ListGroup>{!isLoading && renderBudgetItemsList(budgetItems)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Overview">
      {isAuthenticated ? renderBudgetItems() : renderLander()}
    </div>
  );
}