import React from "react";
import * as TableConstants from "../Tables/constants.js";

import { Doughnut } from "react-chartjs-2";

class PercentSubTypePieChart extends React.Component {
constructor(props) {
 super(props);

 this.state = {
   chartData: {
    labels: props.percents.labels,
     datasets: [
       {
         label: "Percentages",
         backgroundColor: Object.values(TableConstants.CHART_COLORS),
         borderColor: Object.values(TableConstants.CHART_COLORS_BORDER),
         data: props.percents.data,
         borderWidth: 1
       }
     ]
   },
   legend: {
    display: true,
    },
 };
}

render() {

const options={
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    }
};

 return (
 <Doughnut data={this.state.chartData} height={"200%"} width={"100%"} options={ options }/>
 );
}
}

export default PercentSubTypePieChart;