import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../../components/LoaderButton";
import { onError } from "../../../libs/errorLib";
import "./NewAsset.css";
import { API } from "aws-amplify";

export default function NewAsset() {

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0,10);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [typeOfAsset, setTypeOfAsset] = useState("");
  const [assetActive, setAssetActive] = useState(false);
  const [assetNote, setAssetNote] = useState("");
  const [assetDate, setAssetDate] = useState(date);
  const [assetName, setAssetName] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetQuantity, setAssetQuantity] = useState("");
  const [assetSellPrice, setAssetSellPrice] = useState("");

  const handleOnChange = () => {
    setAssetActive(!assetActive);
  };

  function validateForm() {
    return assetNote.length > 0;
  }

  async function handleSubmit(event) {

    event.preventDefault();
  
    setIsLoading(true);

    var assetTotalPrice = assetQuantity * assetSellPrice;
  
    try {
 
      await createBudgetItem({ assetActive, typeOfAsset, assetNote, assetDate, assetName, assetCode, assetQuantity, assetSellPrice, assetTotalPrice});
      history.push("/portfolio/assets");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createBudgetItem(budgetItem) {
    return API.post("portfolio", "/assets/add", {
      body: budgetItem
    });
  }

  return (
    <div className="NewAsset">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Create new asset</h2>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="assetDate" size="lg">
          <Form.Label>Date of Asset</Form.Label>
          <Form.Control 
            type="date" 
            name='assetDate'
            defaultValue={assetDate}
            onChange={(e) => setAssetDate(e.target.value)}
            />
      </Form.Group>
      <Form.Group controlId="subtypeOfItem" size="lg">
          <Form.Label>Type of Asset</Form.Label>
          <Form.Control
            autoFocus
            type="typeOfAsset"
            value={typeOfAsset}
            placeholder="Type of Asset"
            onChange={(e) => setTypeOfAsset(e.target.value)}
          />
        </Form.Group>
      <Form.Group controlId="assetNote" size="lg">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="assetNote"
            value={assetNote}
            placeholder="Asset Note"
            onChange={(e) => setAssetNote(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="assetName" size="lg">
          <Form.Label>Asset Name</Form.Label>
          <Form.Control
            type="assetName"
            value={assetName}
            placeholder="Asset Name"
            onChange={(e) => setAssetName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="assetCode" size="lg">
          <Form.Label>Asset Code</Form.Label>
          <Form.Control
            type="assetCode"
            value={assetCode}
            placeholder="Asset Code"
            onChange={(e) => setAssetCode(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="assetQuantity" size="lg">
          <Form.Label>Asset Quantity</Form.Label>
          <Form.Control
            type="assetQuantity"
            value={assetQuantity}
            placeholder="Asset Quantity"
            onChange={(e) => setAssetQuantity(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="assetSellPrice" size="lg">
          <Form.Label>Asset Sell Price</Form.Label>
          <Form.Control
            type="assetSellPrice"
            value={assetSellPrice}
            placeholder="Asset Sell Price"
            onChange={(e) => setAssetSellPrice(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="assetActive" size="lg">
          <Form.Label>Asset Active</Form.Label>
          <Form.Check
            type="checkbox"
            id="assetActive"
            label="Active"
            checked={assetActive}
            onChange={handleOnChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}