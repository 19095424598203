import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../../libs/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../../components/LoaderButton";
import "./Items.css";
import { useAppContext } from "../../../libs/contextLib";
import ListGroup from "react-bootstrap/ListGroup";

export default function Items() {
  const { id } = useParams();
  const history = useHistory();
  const { isAuthenticated } = useAppContext();
  const [typeOfItem, setTypeOfItem] = useState("");
  const [subtypeOfItem, setSubtypeOfItem] = useState("");
  const [descriptionOfItem, setDescriptionOfItem] = useState("");
  const [amountOfItem, setAmountOfItem] = useState("");
  const [frequencyOfItem, setFrequencyOfItem] = useState("");
  const [budgetItem, setBudgetItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadBudgetItem() {
      return API.get("budget", `/items/${id}`);
    }

    async function onLoad() {
      try {
        const budgetItem = await loadBudgetItem();
        const { typeOfItem, subtypeOfItem, descriptionOfItem, amountOfItem, frequencyOfItem } = budgetItem;

        setTypeOfItem(typeOfItem);
        setSubtypeOfItem(subtypeOfItem);
        setDescriptionOfItem(descriptionOfItem);
        setAmountOfItem(amountOfItem);
        setFrequencyOfItem(frequencyOfItem);

        setBudgetItem(budgetItem);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return typeOfItem.length > 0;
  }
  
  function saveBudgetItem(budgetItem) {
    return API.put("budget", `/items/${id}`, {
      body: budgetItem
    });
  }
  
  async function handleSubmit(event) {
  
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
 
      await saveBudgetItem({
        typeOfItem,
        subtypeOfItem,
        descriptionOfItem,
        amountOfItem,
        frequencyOfItem
      });
      if (typeOfItem === "income"){
        history.push("/budget/income");
      } else if (typeOfItem === "saving"){
        history.push("/budget/savings");
      } else if (typeOfItem === "expense"){
        history.push("/budget/expenses");
      } else {
        history.push("/budget/overview");
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteBudgetItem() {
    return API.del("budget", `/items/${id}`);
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteBudgetItem();
      if (typeOfItem === "income"){
        history.push("/budget/income");
      } else if (typeOfItem === "savings"){
        history.push("/budget/savings");
      } else if (typeOfItem === "expense"){
        history.push("/budget/expenses");
      } else {
        history.push("/budget/overview");
      }
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function renderBudgetItemsList() {
  return (
    <div className="Items">
      {budgetItem && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="subtypeOfItem" size="lg">
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="subtypeOfItem"
                value={subtypeOfItem}
                onChange={(e) => setSubtypeOfItem(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="descriptionOfItem" size="lg">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="descriptionOfItem"
            value={descriptionOfItem}
            onChange={(e) => setDescriptionOfItem(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="amountOfItem" size="lg">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            autoFocus
            type="amountOfItem"
            value={amountOfItem}
            placeholder="Amount of Income"
            onChange={(e) => setAmountOfItem(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="frequencyOfItem" size="lg">
            <Form.Label>Frequency: </Form.Label>
            <select value={frequencyOfItem} onChange={(val) => setFrequencyOfItem(val.target.value)} className="btn btn-sm btn-outline-secondary dropdown-toggle">
              <option value="WEEK">Weekly</option>
              <option value="FORTNIGHT" >Fortnightly</option>
              <option value="MONTH">Monthly</option>
              <option value="QUARTER">Quarterly</option>
              <option value="YEAR">Yearly</option>
            </select>
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </Form>
      )}
    </div>
  );
}

function renderLander() {
  return (
    <div className="lander">
      <h1>Making Budgets</h1>
      <p className="text-muted">A simple tool for making budgets</p>
    </div>
  );
}

function renderBudgetItems() {
  return (
    <div className="budgetItems">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Edit Item</h2>
      <ListGroup>{!isLoading && renderBudgetItemsList()}</ListGroup>
    </div>
  );
}

return (
  <div className="Income">
    {isAuthenticated ? renderBudgetItems() : renderLander()}
  </div>
);
}